import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ValidationToken } from '@haleo-frontend/data-access/models';
import { EnvironmentUtils } from '@haleo-frontend/utils';

@Injectable({
    providedIn: 'root'
})
export class ValidationTokenService {

    constructor(private http: HttpClient) {
    }

    createEmailToken(email: string, appKey: string, locale: string): Observable<ValidationToken> {
        return this.http.post<ValidationToken>(EnvironmentUtils.env.api + 'authenticate/request-validation-link', {
            email,
            appKey,
            locale,
            type: 'email'
        });
    }

    createMergeToken(email: string): Observable<ValidationToken> {
        return this.http.post<ValidationToken>(EnvironmentUtils.env.api + 'validation-tokens', {email, type: 'merge'});
    }

    get(token: ValidationToken): Observable<ValidationToken> {
        return this.http.get<ValidationToken>(EnvironmentUtils.env.api + 'validation-tokens/' + token.uuid);
    }

    validateToken(token: string): Observable<ValidationToken> {
        return this.http.put<ValidationToken>(EnvironmentUtils.env.api + 'validation-tokens/' + token, { status: 'validated' });
    }

    sendScreenerValidation(email: string, client: string, locale: string): Observable<any> {
        return this.http.post<any>(EnvironmentUtils.env.api + 'authenticate/send-screener-validation', {email, locale, client});
    }
}
