import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AppService, EnvironmentUtils, LogUtils, TrackingUtils } from '@haleo-frontend/utils';
import { LanguageService, SessionReplayService } from '@haleo-frontend/data-access/services';

import { ConfigService } from './shared/services/config.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {

    constructor(private appService: AppService,
                private platform: Platform,
                private sessionReplayService: SessionReplayService,
                private ngZone: NgZone,
                private storage: Storage,
                private router: Router,
                private configService: ConfigService,
                private languageService: LanguageService) {
        this.initializeApp();

        this.languageService.localeChangeEvent.subscribe(() => {
            if (this.languageService.locale == 'fr-ca') {
                document.title = 'Dépistage'
            } else {
                document.title = 'Screener'
            }
        });
    }

    initializeApp() {

        this.platform.ready().then(() => {
            LogUtils.init(this.platform.is('ios'), false, this.appService.getAppKey());
            TrackingUtils.init(this.platform, '', EnvironmentUtils.env.firebaseConfig);
            this.sessionReplayService.init();
        });
    }

    navigateByUrl(url: string) {
        return this.ngZone.run(() => this.router.navigateByUrl(url));
    }

    setStorage(key: string, value: any) {
        return this.ngZone.run(() => this.storage.set(key, value));
    }

    clearStorage() {
        return this.ngZone.run(() => {
            this.storage.clear();
        });
    }

    clearConfig() {
        return this.ngZone.run(() => {
            this.configService.reset();
        });
    }
}
